@import '../../scss/variables.scss';

.boxContainer {
  max-width: 100%;
  border-radius: 20px;
  display: flex;
  gap: 40px;
  margin-top: 28px;

  @media (max-width: 850px) {
    padding: 0;
    width: 100%;
    margin: 0;
    gap: 0;
    justify-content: center;
  }
}

.aboutInputField {
  @extend .inputField;
  width: 100%;

  @media (max-width: $size-s) {
    width: 300px;
  }
}

.inputField {
  width: 344px;
  height: 55px;
  background: $white;
  border-radius: 10px;
  border: none;
  opacity: 0.7;
  outline: none;
  padding-left: 13px;

  &:focus {
    opacity: 1;
  }

  @media (max-width: $size-s) {
    width: 300px;
  }
}

.title {
  width: 705px;
  // height: 51px;
  font-weight: 600;
  font-size: 42px;
  line-height: 51px;
  letter-spacing: -0.13125px;
  color: $white;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);

  @media (max-width: 850px) {
    font-size: 30px;
    text-align: center;
    width: 100%;
  }
}

.text {
  width: 705px;
  height: 48px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $white;
  margin-top: 14px;

  @media (max-width: 850px) {
    display: none;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @media (max-width: 850px) {
    margin-top: 10px;
  }

  @media (max-width: $size-s) {
    text-align: center;
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.groupInput {
  display: flex;
  gap: 20px;

  &:first-child {
    margin-bottom: 20px;
  }

  @media (max-width: 850px) {
    flex-direction: column;
    align-items: center;
  }
}

.textAreaField {
  background: $white;
  border: 1px solid $white;
  border-radius: 10px;
  opacity: 0.7;
  width: 707px;
  height: 100px;
  resize: none;
  outline: none;
  // margin-top: 20px;
  padding: 13px 0 0 13px;

  @media (max-width: 850px) {
    width: 300px;
  }

  &:focus {
    opacity: 1;
  }
}

.btnWrapper {
  margin-top: 30px;

  @media (max-width: 850px) {
    display: flex;
    justify-content: flex-start;
    margin-left: 10px;
  }
}

.btnTextAreaWrapper {
  width: 707px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 850px) {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.logo {
  height: 58px;
}

.position {
  position: relative;
}

.textAreaWrapper {
  margin-top: 20px;
  position: relative;
  display: flex;
  justify-content: center;
}

.short {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media(min-width: 768px) {
    width: 700px;
  }

  @media(min-width: 992px) {
    flex-direction: column;
  }
}

.input {
  width: 100%;
  ::placeholder {
    color: #fff !important;
  }
}

.form {
  width: 100%;
}

.send_btn {
  display: block;
  width: 100%;
  height: 40px;
  border-radius: 12px;
  color: #fff;
  font-weight: 600;
  background-color: #8894FF;
  border: none;
  font-size: 16px;
  margin: 0 auto;
}

.description {
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
  @media(min-width: 1200px) {
    // padding-left: 30px;
  }

}

.large_input {
  margin-bottom: 20px !important;
}
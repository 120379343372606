@import "./scss/variables.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  user-select: none;
}

h1,
h2,
h3,
p {
  font-family: "Montserrat", sans-serif;
  color: #2b3134;
}


html {
  background-color: #000 !important;
}

body {
  background-color: #000 !important;
  max-width: 100vw;
}

.body {
  position: relative;
  background-color: #000;
  height: 100vh;
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

}

.ant-form-item-row {
  width: 100%;
}

.ant-drawer-header-title {
  display: flex;
  justify-content: flex-end;
}

.ant-tree-switcher,
.ant-tree-switcher-noop {
  display: none !important;
}

.ant-tree {
  background-color: #000;
}

.ant-tree-indent-unit {
  opacity: 0;
}

.ant-tree-treenode,
.ant-tree-treenode-switcher-open {
  width: 100%;

}

.ant-tree-node-content-wrapper,
.ant-tree-node-content-wrapper-open {
  width: 100%;
}

.ant-input-textarea textarea {
  resize: none;
  overflow-y: hidden;
  color: #fff !important;

  ::placeholder {
    color: #fff !important;
  }

  /* Это предотвращает появление вертикальной полосы прокрутки */
}

.ui.dimmer {
  z-index: 2000;
}

// .image-gallery-image {
//   width: 470px;
//   height: 470px;
//   object-fit: cover;
// }
// .image-gallery-slides {
//   aspect-ratio: 1;
// }



// .image-gallery-slide,  .image-gallery-center  {
//   width: 100%;
//   object-fit: cover !important;
//   height: 100%;
// }

// .image-gallery-slide, .image-gallery-image {
//   object-fit: cover !important;
// }

// .image-gallery-fullscreen-button, .image-gallery-play-button {
//   display: none;
// }

// .image-gallery {
//   width: auto;
//   aspect-ratio: 1;
//   max-height: 500px;
// }

// .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
//   height: 47px;
//   width: 60px;
// }

// .image-gallery-content .image-gallery-slide .image-gallery-image {
//   max-height: none;
// }

.image-gallery-thumbnails {
  position: absolute;
  bottom: 10px;
  /* расположение миниатюр относительно нижней части основного изображения */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}


.image-gallery-thumbnail img {
  width: 50px;
  /* размер миниатюры */
  height: 50px;
  object-fit: cover;
  border: 2px solid #fff;
  /* белая рамка вокруг миниатюры */
  border-radius: 5px;
  /* скругление углов */
}

.image-gallery-image {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: cover !important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {

  aspect-ratio: 1;
  border: none;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border: none;
}

.image-gallery-thumbnail:hover {
  border: none;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 58px;
  width: 60px;
}

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0;
  overflow: hidden;
}

.ui.dimmer {
  z-index: 2000;
}

.image-gallery-icon:focus {
  border: none;
  outline: none;
}

.image-gallery-left-nav {
  display: block;
  z-index: 1000;
}

.infinite-scroll-component::-webkit-scrollbar {
  display: none;
}


.ant-upload {
  border-radius: 50% !important;
}

.ant-drawer-content-wrapper-hidden,
.ant-drawer-content-wrapper {
  background-color: $bg-main !important;
  width: 100% !important;
}

.ant-drawer-content {
  background-color: $bg-main !important;
}

.ant-input,
.ant-input-affix-wrapper {
  height: 40px;
  border-radius: 12px;
  border: 1px solid #fff;
  color: #fff !important;
  background: transparent !important;
  font-family: "Montserrat", sans-serif;

  ::placeholder {
    color: #fff !important;
  }
}

.ant-input-suffix {
  color: #fff !important;
}

.ant-input-affix-wrapper {
  padding-top: 0;
  padding-bottom: 0;
}

input::placeholder {
  color: #fff !important;
}

.ant-select {
  height: 40px;
}

.ant-select-selector {
  background: none !important;
  height: 40px !important;
  border-radius: 12px !important;
}

.ant-select-selection-placeholder {
  color: #fff !important;
  font-size: 16px;
  font-weight: 600;
}

.ant-select-arrow {
  color: #fff !important;
}

.ant-select-item-option-content {
  color: #fff !important;
  font-weight: 600;
}

.ant-select-item-option-selected {
  background-color: transparent !important;
  font-weight: 700 !important;
}

.ant-select-selection-item {
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 16px;
}

textarea::placeholder {
  color: #fff !important
}

.music_header {
  @media(min-width: 1200px) {
    display: none;
  }
}

.ant-pagination-item {
  background-color: transparent !important;
  color: #fff !important;
}

.ant-pagination-item-link {
  color: #fff !important;
}

.ant-table-cell {
  font-size: 16px;
  font-weight: 700;
}

th.ant-table-cell::before {
  display: none;
}

.ant-popover-inner {
  background-color: #000 !important;
  border-radius: 12px !important;
}

.ant-popover-arrow::before {
  background: #000 !important;
}

.ant-modal .ant-modal-content {
  background-color: #000;
  padding: 0;
}

.ant-modal-header {
  margin: 10px !important;
}
.ant-modal-footer {
  margin: 10px !important;
}

.ant-upload {
  width: 100% !important;
  aspect-ratio: 1 !important;
  height: 100% !important;
  border: 2px solid transparent !important;
  /* Ширина рамки */
  border-radius: 10px;
  /* Скругление углов */
  background-image: linear-gradient(black, black), linear-gradient(to bottom, #9507FF, #000000);
  /* Градиент сверху вниз */
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border-radius: 50%;

  @media(min-width: 1200px) {
    border: 3px solid transparent !important;
    /* Ширина рамки */

  }
}

.deposit_table .ant-table-container .ant-table-content table .ant-table-tbody .ant-table-row.ant-table-row-level-0{
background-color: #000 !important;
color: #fff !important;
}

.deposit_table .ant-table-container .ant-table-content table .ant-table-tbody .ant-table-row.ant-table-row-level-0 .ant-table-cell {
  color: #fff !important;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
}

.deposit_table .ant-table-container .ant-table-content thead .ant-table-thead tr th{
  padding: 10px !important;
}

.ant-upload-wrapper {
  width: auto !important;
  aspect-ratio: 1;
}

.ant-tree-list-holder {
  background-color: #000;
}

.ant-table-cell .ant-table-cell-row-hover {
  background-color: none !important;
}

.ant-table-tbody .ant-table-row.ant-table-row-level-0:nth-child(even) {
  background-color: #000 !important;

  /* Цвет фона для четных строк */
  .ant-table-cell {
    color: #fff !important;
  }
}

.ant-table-tbody .ant-table-row.ant-table-row-level-0:nth-child(odd) {
  background-color: #E4C2FF !important;

  /* Цвет фона для нечетных строк */
  .ant-table-cell {
    color: #000 !important;
  }
}

.ant-table-tbody .ant-table-row.ant-table-row-level-0:nth-child(even) .ant-table-cell p {
  color: #fff;
  font-size: 16;
  font-weight: 400;
  /* Цвет текста для четных строк */
}

.ant-table-tbody .ant-table-row.ant-table-row-level-0:nth-child(odd) .ant-table-cell p {
  color: #000;
  /* Цвет текста для нечетных строк */
  font-size: 16;
  font-weight: 400;
}

.ant-table-tbody .ant-table-row.ant-table-row-level-0,
.ant-table-cell-row-hover {
  background-color: inherit !important;
  /* Сохраняет цвет фона при наведении */
}

.ant-table-cell,
.ant-table-column-sort {
  background: inherit !important
}

/* Стили для hover текста */
.ant-table-tbody .ant-table-row.ant-table-row-level-0:hover .ant-table-cell p {
  color: inherit !important;
  /* Сохраняет цвет текста при наведении */
}

.ant-table-thead {
  background: #C983FF !important;
}

.ant-pagination {
  align-items: center;

  a {
    color: #fff !important;
  }
}

.ant-select-dropdown {
  color: #000 !important;
}

.ant-table-pagination .ant-select-selector {
  background-color: black;
  /* Цвет фона для селектора размера */
  color: white;
  /* Цвет текста для селектора размера */
}

.ant-table-pagination .ant-select-dropdown {
  background-color: black;
  /* Цвет фона для выпадающего списка */
  color: white;
  /* Цвет текста для выпадающего списка */
}

.ant-table-pagination .ant-select-item {
  color: white;
  /* Цвет текста для элементов в списке */
}

.ant-table-pagination .ant-select-item-option-selected {
  background-color: #1a1a1a;
  /* Цвет фона для выбранного элемента */
}

.ant-input-number,
.ant-input-number:hover,
.ant-input-number-focused {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #000 !important;
  border: 1px solid #fff !important;
  border-radius: 12px;
  color: #fff !important;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

.ant-input-number-input-wrap {
  width: 100%;

}

.ant-input-number-input {
  color: #fff !important;
  font-family: "Montserrat", sans-serif !important;

}

.ant-modal-footer button,
.ant-modal-footer button:hover {
  background-color: #000 !important;
  background: #000 !important;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 12px;
}

.ant-modal-footer button span:hover {
  background-color: transparent !important;
  background: transparent !important;
}

.ant-slider-rail {
  background-color: #2b3134 !important;
}

.ant-modal-title {
  background-color: #000 !important;
  color: #fff !important;
}

.ant-modal-footer button :hover {
  background: #000 !important;
  color: #fff !important;
}


.ant-switch {
  background: #FF75E6 !important;
}

.ant-switch-checked {
  background: #9507FF !important;
}

.ant-select-selection {
  background: #000;
}

.ant-select-item, .ant-select-item-option-active {
  background-color: #000 !important;
  :hover {
  background-color: #000 !important;

  }
}

.ant-select-dropdown {
  padding: 0 !important;
  background-color: #000;
}

.ant-empty-image {
  display: none;
}

.ant-empty-description {
  display: none;
}

.ant-table-column-sorters {
  flex-direction: row-reverse !important;
}
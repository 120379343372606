.buy_tokens_header {
 width: 100%;
 height: 40px;
 background-color: #C983FF;
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: 12px 12px 0 0;
}

.buy_tokens_header__text {
 font-weight: 800;
 text-transform: uppercase;
 padding: 0;
 margin: 0;
 color: #9507FF;
 font-size: 20px;
}


.bonus_filling {
 padding: 0 10px;
 p {
  color: #fff;
  margin-bottom: 10px;
  font-size: 14px;
 }
}

.bonus_filling_header {
 font-weight: 600;
 margin-top: 20px;
 font-size: 20px !important;
}

.button {
 margin-top: 20px;
 margin-bottom: 24px;
 height: 40px;
 width: 100%;
 background-color: #9507FF;
 color: #fff;
 border: none;
 border-radius: 12px;
 font-weight: 600;
 font-size: 16px;
}
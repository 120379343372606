@import '../../../scss/variables.scss';

.container {
  display: flex;
  flex-direction: column;

  .titleWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 19px;

    @media screen and (max-width: $size-s) {
      color: #000;
      //background: #000035;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.196351);
      border-radius: 9.62687px 9.62687px 0px 0px;
    }

    h2 {
      @media screen and (max-width: $size-s) {
        color: #000;
      }

      font-weight: 500;
      font-size: 24px;
      line-height: 44px;
      color: $grey-dark;
      margin-left: 8px;
    }

    img {
      width: 33px;
      height: 39px;
      margin-top: 15px;
    }
  }

  .buttonsWrapper {
    padding-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 30px;

    .btnLargeWrapper {
      display: flex;
      gap: 19px;
      margin-bottom: 25px;
      flex-wrap: wrap;
      justify-content: center;
    }

    .btnSmallWrapper {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(268px, 268px));
      grid-column-gap: 15px;
      grid-row-gap: 25px;
      justify-content: center;

      @media (max-width: $size-s) {
        font-size: 11.7px;
        line-height: 14px;
        text-transform: uppercase;
        grid-template-columns: repeat(auto-fill, minmax(140px, 164px));
        grid-column-gap: 5px;
        grid-row-gap: 8px;
      }
    }
  }
}

.btnSmall {
  position: relative;
  cursor: pointer;
  width: 270px;
  height: 140px;
  border: 2px solid $white;
  border-radius: 11.25px;

  &:hover {
    border: 2px solid $red;
  }


  .btnTitle {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -12px;
    text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: $white;

    @media (max-width: $size-s) {
      font-size: 11.7px;
      line-height: 14px;
      text-transform: uppercase;
    }
  }

  @media (max-width: $size-s) {
    width: 164px;
    height: 60px;
    font-size: 12px;
    line-height: 14px;
  }
}

.largeBtn {
  cursor: pointer;
  width: 360px;
  height: 80px;
  background-color: $bg-main;
  border: 2px solid $bg-main;
  border-radius: 15px;

  &:hover {
    border: 2px solid $sea-green;
  }

  span {
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
    text-transform: uppercase;
    color: $white;
  }

  @media (max-width: $size-s) {
    width: 361px;
    height: 48px;
    font-size: 16px;
    line-height: 19px;
  }
}
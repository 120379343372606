@import '../../scss/variables.scss';

.wrapper {
  padding: 0 20px;
  margin-top: 30px;
  @media (max-width: $size-l) {
    padding: 0 20px;
  }

  @media(min-width: 1200px) {
    margin-top: 18px;
  }
}

.header {
  margin-bottom: 20px !important;
  text-transform: uppercase;
  @media(min-width: 1200px) {
    margin-left: 40px;
  }
}

.subHeader {
  margin-top: 20px;
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
}

.text {
  font-size: 16px;
  margin: 20px 0;
  text-align: justify;
  color: #fff;

}

.textBronze {
  font-size: 16px;
  color: rgb(61, 32, 23);
  margin: 20px 0;
}

.list {
  font-size: 16px;
  margin: 10px 0;
}

.list li {
  margin: 5px 50px;
  list-style-type: disc;
}


.rules_container {
  margin-top: 40px;
  @media(min-width: 1200px) {
    margin-left: 30px;
    max-width: 900px;
    margin-top: 20px;
  }
}

.player {
  height: 100%;
  width: 100%;
}

iframe {
  width: 100%;
}
.name {
  margin-right: 10px;
}

.name {
  color: #fff;
}

.commentIconWrapper {
  width: 40px;
  aspect-ratio: 1;
  display: flex;
  margin-right: 10px;
}

.iconWrapper {
  aspect-ratio: 1;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 50%;
  display: flex;
  margin-right: 10px;
  height: 40px;
  @media(max-width: 991px) {
    height: 40px;
  }
}

.reply {
  padding: 0;
  color: #fff;
  font-size: 12px;
}

.actions_rate_like, .actions_rate_dislike {
  font-size: 12px;
  padding: 0;
  color: #fff;
}

.actions_rate_dislike {
  margin-right: 10px;
  margin-left: 10px;
}


.reply:hover {
  color: #fff !important;
}
.reply_field {
  margin-top: 20px;
}

.avatar {
  width: 100%;

}


.comment_area {
  border: 1px solid #fff;
  width: 100%;
  min-height: 32px;
}

.input_group {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.controls {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.custom_buttons {
  border: none;
  background-color: none;
  &:active {
    background-color: none;
  }
  &:disabled {
    background: none;
  }
}

.tree {
  width: 100%;
}

.date {
  color: #fff;
}

.divider {
  margin: 4px 0;
}

.controlBtn_reply {
  border: none;
  background-color: transparent;
  color: #fff;
  * {
    fill: #fff;
  }
}

.avatar_col {
  display: flex;
  align-items: center;
}

.avatar_blank {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  color: #fff;
  max-width: 300px;
  @media(min-width: 768px) {
    max-width: none;
  }
}

@import '../../scss/variables.scss';

.wrapper {
  padding: 0 100px 0px 0px;

  @media (max-width: $size-l) {
    padding: 25px 15px 20px 30px;
  }
}

.header {
  margin-bottom: 20px !important;
  text-transform: uppercase;
}

.subHeader {
  margin-top: 20px;
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;

}

.subHeaderB {
  margin: 45px 0 10px 0;
  font-size: 20px;
  color: #fff;
}

.text {
  font-size: 16px;
  color: #fff;
}

.textRed {
  font-size: 16px;
  color: red;
}

.list {
  font-size: 16px;
  margin: 10px 0;
  color: #fff;
}

.list li {
  margin: 5px 50px;
  list-style-type: disc;
  @media screen and (max-width: $size-m) {
    margin: 5px 20px;
  }
}

.footer {
  margin-top: 40px;
  font-size: 16px;

  .footnote {
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
    font-size: 14px;
  }
}

.container {
  margin-top: 45px;
  @media(min-width: 1200px) {
    margin-top: 18px;
  }
}
@import '../../../scss/variables.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #000;

  @media screen and (max-width: 430px) {
    display: block;
  }

  // position: fixed;
  z-index: 99;

  .cross {
    display: none;
    position: absolute;
    right: 35px;
    top: 35px;
    fill: black;
  }
}

.footer_btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;

  button {
    color: #fff;
    background: transparent;
    width: 225px;
    border-radius: 12px;
    font-size: 20px;
    font-weight: 600;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.footer_text {
  text-align: center;
  font-size: 20px;
  color: #fff;
  margin-top: 20px;
}

.logo {
  width: 390px;
  height: auto;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 30px;
}
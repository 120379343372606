@import '../../scss/variables.scss';



.container {
  background: $bg-main;
  min-height: 100vh;
  width: 100%;
  position: relative;
  min-height: 100vh;

  @media (max-width: 1200px) {
    width: 100%;
    margin-left: 0px;
    min-height: 100vh;
  }

  @media(min-width: 1200px) {}
}



.wrapper {
  background-color: $bg-main;
  overflow: hidden;
  width: 100%;
  padding: 0 80px;
  @media (max-width: 1200px) {
    padding: 0;
    margin-top: 0;
  }
  @media(min-width: 992px) {
    padding: 0 40px;
    margin-top: 0px;
  }
  @media(min-width: 1400px) {
    padding: 0 80px;
  }
}

.userMenu {
  width: 437px;
  height: 100vh;
  border-radius: 0 20px 20px 0;
}

.notificationWrapper {
  display: flex;
  gap: 15px;
  position: absolute;
  right: 15px;
  top: 15px;
}

.logoutWrapper {
  display: flex;
  gap: 15px;
  position: absolute;
  right: 5px;
  top: 75px;
  color: white;
  z-index: 10;
  // @media (max-width: 1200px) {
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }
}

.layout_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 12px;
  @media(min-width: 1200px) {
    display: none;
  }
}

.account_wrapper {
  height: 30px;
  width: 30px;
  object-fit: contain;
  overflow: hidden;
  border-radius: 50%;
}

.account_avatar {
  width: 100%;
}

.button_wallet {

}

.button_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    font-family: "Montserrat";
    font-weight: 700;
    color: #fff;
    font-size: 20px;
  }
}

.logo {
  position: absolute;
  top: 5px;
  left: 40%;

  @media(max-width: 992px) {
    display: none;
  }
}

.logoPlayporn {
  position: absolute;
  top: -30px;
  z-index: 10;

  @media(min-width: 992px) {
    display: none;
  }
}

.preFooter {
  background-color: #b5b5b5;
  height: 386px;
  z-index: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 47px;

  .preFooterTitle {
    width: 592px;
    height: 102px;
    font-weight: 600;
    font-size: 42px;
    line-height: 51px;
    letter-spacing: -0.13125px;
    margin-bottom: 47px;

    @media (max-width: 600px) {
      font-size: 30px;
      line-height: 37px;
      text-align: center;
      letter-spacing: -0.09375px;
      width: 335px;
      height: 74px;
    }
  }
}

.footer {
  // padding: 50px 50px 20px 0px;
  display: flex;
  bottom: 0;
  position: relative;
  padding: 20px;

  @media(min-width: 992px) {
    //padding-left: 0px;
    // padding: 50px 50px 20px 25px !important;
  }

  @media (max-width: 600px) {
    // padding: 30px 50px 20px 25px !important;
  }

  .text {
    font-size: 16px;
    color: #fff
  }

  .year {
    font-size: 16px;

    color: #fff
  }
}

.head_wrapper {
  display: flex;
  // background-color: #b5b5b5;
  padding: 30px 20px 12px 20px
}

.footer_content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;

  @media(min-width: 600px) {
    flex-direction: row;
  }
}

.address {
  color: rgb(105, 105, 105);
  padding: 0;
  font-size: 12px;
}


/////
///
///
.logo_col {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;


  @media(min-width: 992px) {
    justify-content: flex-end;
  }

  @media (min-width: 1200px) {
    justify-content: flex-start;
  }
}


.button_link {
  height: fit-content;
  width: fit-content;
  padding: 0;
  margin: 0;
  width: 100%;
}

.footer_button {
  height: 40px;
  width: 100%;
  background: transparent;
  color: #fff;
  border: none !important;
}

.big_logo {
  display: none;

  @media(min-width: 1200px) {
    display: block;
    width: 390px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 90px;
    margin-bottom: 40px;
  }
}
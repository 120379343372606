@import '../../scss/variables.scss';

.wrapper {
  padding: 0;

  @media (max-width: $size-l) {
    padding: 25px 15px 20px 30px;
  }
}

.wrapper_text {
  margin-top: 45px;
  @media(min-width: 1200px) {
    margin-top: 18px;
  }
}
.header {
  margin-bottom: 20px !important;
  text-transform: uppercase;
}

.subHeader {
  margin-top: 20px;
  font-size: 22px;
}

.text {
  font-size: 16px;
}

span.text {
  font-size: 16px;
  margin: 20px 0;
}

.textBronze {
  font-size: 25px;
  color: rgb(61, 32, 23);
  margin: 20px 0;
  font-style: italic;

}

.list {
  font-size: 25px;
  margin: 10px 0;
}

.list li {
  margin: 5px 50px;
  list-style-type: disc;
}

.input {
  margin: 0;
}

.text {
  color: #fff;
}
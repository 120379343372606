.auction {
 margin-top: 5px;
 margin-bottom: 5px;
 display: flex;
 flex-direction: column;
 position: relative;
 width: 100%;
 // padding: 20px;
 background: linear-gradient(90deg, #9507FF 0%, rgba(255, 57, 179, 0) 100%);
 border-radius: 12px;
 overflow: hidden;
}

.wrapper {
 position: relative;
 display: flex;
 flex-direction: row;
 width: 100%;
 overflow: hidden;
 padding: 20px;
 object-fit: cover;
}

.tag {
 padding: 10px;
 border-radius: 12px;
 border: 2px solid #fff;
 margin: 5px;
 margin-left: 0;
 width: fit-content;
 height: fit-content;

 p {
  color: #fff;
  font-weight: 600;
 }
}

.header {
 color: #fff;
 // font-size: 20px;
 margin-bottom: 10px;
 display: -webkit-box;
 -webkit-line-clamp: 2;
 /* Ограничение текста двумя строками */
 -webkit-box-orient: vertical;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: normal;
 margin-top: 10px;
}

.info {
 display: flex;
 flex-direction: column;
 width: 50%;
}

.cover {
 width: 50%;
 height: auto;

 img {
  width: 70%;
  border-radius: 12px;
 }
}

.background_img {
 position: absolute;
 width: 100%;
 height: auto;
 filter: blur(20px);
 mix-blend-mode: overlay;

}

.bid_button {
 position: relative;
 display: block;
 // margin-top: 10px;
 margin-bottom: 20px;
 background-color: transparent;
 border: 2px solid #fff;
 color: #fff;

 margin-top: 10px;
 height: 40px;
 font-size: 16px;
 font-weight: 600;
 border-radius: 12px;
}

.bid_button {
 position: relative;
 display: block;
 // margin-top: 10px;
 margin-bottom: 20px;
 background-color: transparent;
 border: 2px solid #fff;
 color: #fff;

 margin-top: 10px;
 height: 40px;
 font-size: 16px;
 font-weight: 600;
 border-radius: 12px;
}

.pay_button {
 position: relative;
 display: block;
 // margin-top: 10px;
 margin-bottom: 20px;
 background-color: transparent;
 border: none;
 background: #9507ff;

 color: #fff;

 margin-top: 10px;
 height: 40px;
 font-size: 16px;
 font-weight: 600;
 border-radius: 12px;
}

.bid_button_disabled {
 position: relative;
 display: flex;
 align-items: center;
 justify-content: center;
 // margin-top: 10px;
 margin-bottom: 20px;
 background-color: transparent;
 border: 2px solid #fff;
 color: #fff;
 width: 50%;
 margin-top: 10px;
 height: 40px;
 font-size: 16px;
 font-weight: 600;
 border-radius: 12px;
}

.date {
 color: #fff;
}

.modal {
 border-radius: 12px;
 padding: 0 !important;
 overflow: hidden;
}

.modal_wrapper {
 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: space-between;
 background-color: #ff39b3;
 padding: 30px 60px;

 @media(max-width: 1199px) {
  flex-direction: column;
  padding: 10px;
 }
}

.name_modal {
 font-size: 20px;
 color: #fff;
 font-weight: 600;
 margin-top: 10px;
}

.paragraph_modal {
 margin-top: 10px;
 color: #fff;
}

.tag_modal {
 margin-top: 10px;
}

.cover_modal {
 width: 100%;
 height: auto;
 margin-bottom: 10px;

 img {
  width: 70%;
  border-radius: 12px;
 }
}

.list_modal {
 width: 100%;
}

.scroll {
 background-color: #fff;
 padding: 20px;
 border-radius: 12px;
 width: 100%;
}

.bid_button_fast {
 margin-left: 5px;
 margin-right: 5px;
 background-color: none;
 color: #fff;
 border: 2px solid #fff;
 font-size: 16px;
 font-weight: 600;
 height: 40px;
 background-color: transparent;
 margin-bottom: 10px;
}

.fast_buttons {
 margin-top: 10px;

}

.bid_button_fast {
 margin-left: 5px;
 margin-right: 5px;
 background-color: none;
 color: #fff;
 border: 2px solid #fff;
 font-size: 16px;
 font-weight: 600;
 height: 40px;
 background-color: transparent;
 margin-bottom: 10px;

 :first {
  margin-left: 0px;

 }
}


.popoverInfo {
 color: #fff;
}

.bidButtonSuffix {
 margin-left: 10px;
}

.timer {
 background-color: #fff;
 border-radius: 6px;
 width: 137px;
 display: flex;
 align-items: center;
 justify-content: center;
 padding: 0 10px;
}

.timer_text {
 color: #9507FF;
 font-weight: 600;
 text-align: start;
 width: 100%;
}
.wrapper {
 padding: 0 20px;
 margin-top: 20px;

 @media(min-width: 1200px) {
  margin-top: 18px;
 }
}

.filters {
 display: flex;
 flex-direction: column;
}

.button_search {
 padding: 0;
 display: flex;
 align-self: center;
}

.settings {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 margin-top: 10px;
 align-items: center;
}

.selection {
 background-color: transparent !important;
 color: #fff !important;
 width: 90px;
}

.grid {
 display: grid;
 grid-template-columns: 1fr 1fr 1fr;
 grid-template-rows: 1fr 1fr;
 row-gap: 10px;
 column-gap: 10px;
}

.sort_btn {
 height: 40px;
 width: 90px;
 background: transparent;
 border: 1px solid #fff;
 color: #fff;
 font-size: 16px;
 font-weight: 600;
 border-radius: 12px;
}

.sort_by {
 display: flex;
 flex-direction: column;
 margin-top: 10px;
}

.genre_value {
 background-color: #fff;
 height: 100%;
 width: 90px;
}

.songs {
 display: flex;
 margin-top: 20px;
 flex-direction: column;
}

.filters_btn {
 display: flex;
 border: 1px solid #fff;
 background: none;
 flex-direction: row;
 width: fit-content;
 height: 40px;
 align-items: center;
 border-radius: 12px;
 width: 150px;

 p {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
 }
}

.filters_all {
 display: flex;
 flex-direction: column;
 justify-content: space-between;

}

.filters_column {
 display: flex;
 flex-direction: row;
 margin-top: 10px;
 justify-content: space-between;
}

.all_genres {
 display: grid;
 grid-template-columns: 1fr 1fr;
 row-gap: 10px;
 column-gap: 10px;
 margin-top: 10px;
}

.filter_button {
 height: 40px;
 background: transparent;
 color: #fff;
 border: 1px solid #fff;
 border-radius: 12px;
 font-weight: 600;
}

.filter_buttons {
 display: flex;
 flex-direction: row;
 justify-content: space-between;

 div button {
  @media(max-width: 1199px) {
   margin-bottom: 10px;
  }
 }
}

.all_filters_btns {
 height: 40px;
 background-color: transparent;
 color: #fff;
 font-weight: 600;
 border-radius: 12px;
 margin-right: 10px;

}

.clear_btn {
 display: block;
 height: 40px;
 background-color: transparent;
 color: #fff;
 font-weight: 600;
 border-radius: 12px;
 right: 0;
}

.button {
 height: 40px;
 display: flex;
 align-items: center;
 justify-content: center;
 background: transparent;
 color: #fff;
 font-weight: 600;
 border: 1px solid #fff;
 border-radius: 12px;
 margin-bottom: 10px;
}

.checkout_wrapp {
 padding: 0 20px;
 display: flex;
 flex-direction: column;

 @media(min-width: 1200px) {
  margin-bottom: 200px;
 }

}

.checkout_cover {
 max-width: 700px;
 height: auto;
 margin-top: 40px;
}

.checkout_info {
 margin-top: 20px;

 p {
  font-size: 16px;
  color: #fff;
 }
}

.checkout_actions_header {
 height: 40px;
 width: 100%;
 border-radius: 12px 12px 0 0;
 background: #F88F93;
 display: flex;
 align-items: center;
 justify-content: center;
 margin-bottom: 20px;
 margin-top: 20px;

 h2 {
  text-align: center;
  text-transform: uppercase;
  color: #F12027;
  font-size: 20px;
  font-weight: 800;
 }
}

.checkout_actions_price {
 height: 40px;
 border-radius: 12px;
 box-shadow: 0px 1px 0px 0px rgb(255, 255, 255);

 p {
  font-size: 20px;
  color: #fff;
  text-align: center;
  font-weight: 500;
 }
}

.checkout_actions_buy {
 width: 100%;
 height: 40px;
 color: #fff;
 background-color: #F12027;
 border-radius: 12px;
 border: none;
 margin-top: 20px;
 font-size: 20px;
 font-weight: 500;

 &:hover {
  color: #F12027 !important;
  background-color: #fff;
 }

 &:active {
  color: #F12027 !important;
  background-color: #fff;
 }

}

.buy {
 margin-bottom: 20px;
 background-color: #F12027;
 height: 40px;
 border-radius: 12px;
 border: none;
 color: #fff;
 font-weight: 600;
 font-size: 16px;
 width: 100%;
}

.banner_wrapper {
 width: 100%;
 height: 100%;
 object-fit: cover;
 overflow: hidden;
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: 12px;
 // margin-top: 20px;
 margin-bottom: 20px;
}

.banner {
 width: 100%;
 height: auto;
}

.under_get {
 color: #fff;
 margin-top: 20px;
}

.info {
 font-size: 11px;
 word-wrap: break-word;
 color: #fff;
 font-weight: 500;
 font-style: italic;
 span {
   font-style: normal;
 }
 .header {
  font-weight: bold !important;
 }
 @media(min-width: 1200px) {
  font-size: 12px;
 }
}

@import '../../scss/variables.scss';

.container {
  padding: 20px 20px 0 20px;

  @media screen and (max-width: 370px) {
    padding: 10px 10px 100px 10px;
  }
  @media screen and (max-width: $size-m) {
    // padding-bottom: 190px;
  }
  .btnsWrapper {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    font-weight: 500;
    @media (max-width: 870px) {
      flex-direction: column;
      align-items: center;
    }
    .activeBtn {
      cursor: pointer;
      font-size: 28px;
      line-height: 44px;
      color: $grey-dark;
      width: 258px;
      height: 50px;
      background-color: $white;
      border: none;
      text-decoration: underline;
      text-decoration-color: #C8102E;
      text-decoration-thickness: 4px;
      text-underline-offset: 7px;

      font-family: Montserrat, sans-serif;
    }
    .notActiveBtn {
      cursor: pointer;
      font-size: 28px;
      line-height: 44px;
      color: $grey-dark;
      width: 258px;
      height: 50px;
      border: none;
      border-radius: 10px;
      background: none;
      font-family: Montserrat, sans-serif;
    }
  }
}

.vs_icon {
  width: 70px;
  height: auto;
  @media (min-width: 992px) {
    width: 100px;
    height: auto;
  }
}

.content {
  margin-top: 50px;
  @media(min-width: 1200px) {
    margin-top: 18px;
  }
}

.wrapper {
 border: 3px solid #fff;
 width: 100%;
 margin-left: auto;
 margin-right: auto;
 border-radius: 12px;
 margin-top: 10px;
 margin-bottom: 10px;
 // padding: 10px;
}

.moreless {
 display: flex;
 align-items: center;
 border: none;
 color: #fff;
 background: transparent;
 font-weight: 600;
 margin-left: auto;
 margin-right: auto;
 margin-top: 10px;
 margin-bottom: 10px;
}
.buy_tokens_header {
 width: 100%;
 height: 40px;
 background-color: #C983FF;
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: 12px 12px 0 0;
}

.buy_tokens_header__text {
 font-weight: 800;
 text-transform: uppercase;
 padding: 0;
 margin: 0;
 color: #9507FF;
 font-size: 20px;
}

.input_items {
 display: block;
 margin-top: 10px;
}

.input:nth-child(1) {
 margin-bottom: 10px;
}

.withdraw {
 width: 100%;
 margin-bottom: 10px;
 height: 40px;
 background-color: #9507FF;
 color: #fff;
 border-radius: 12px;
 border: none;
 font-weight: 700;
 font-size: 16px;
 margin-top: 20px;
}

.popover_content{
 p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 10px;
 }
 button {
  position: relative;
  display: block;
  margin: 0 auto;
 }
}

.withdraw_pop {
 width: 100%;
 margin-bottom: 10px;
 background-color: #9507FF;
 color: #fff;
 border-radius: 12px;
 border: none;
 font-weight: 700;
 font-size: 16px;
 margin-top: 20px;
}

.table_wrapper {
 width: 100%;
 height: min-content;
 overflow: hidden;
 object-fit: cover;
 border-radius: 12px 12px 0 0;
 margin-top: 20px;
}


.description {
 padding: 20px 0 10px 0;
 p {
  color: #fff;
  font-size: 14px;
 }
}

.validation_suffix {

}
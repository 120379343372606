@import '../../scss/variables.scss';

.payment_select {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.payment_buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;

  &_m {
    width: 35px;
  }

  &_v {
    width: 55px;
  }
}

.slider_block {
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-top: 20px;
}

.buy_tokens_header {
  width: 100%;
  height: 40px;
  background-color: #C983FF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px 12px 0 0;
}

.buy_tokens_header__text {
  font-weight: 700;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  color: #9507FF;
  font-size: 20px;

}

.buy_tokens_inner_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;

  h2 {
    color: #fff;
    font-size: 20px;
  }
}

.buy_usdt_inner_header {
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  margin-top: 15px;

  h2 {
    color: #fff;
    font-size: 20px;
  }
}

.trc20 {
  margin-left: 10px;
}

.step {
  margin-top: 20px;
  color: #fff;
  font-size: 18px;
}

.input_code {
  height: 40px;
}

.copy_code {
  width: 100%;
  margin-top: 10px;
  height: 40px;
  border-radius: 12px;
  background-color: #9507FF;
  color: #fff;
  border: none;
  font-weight: 700;
  font-size: 16px;
}

.other_header {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}

.other_wrapper {
  padding: 10px;
}

.other_icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.moonpay {
  width: 100px;
}

.payments {
  width: 200px;
}


.iframe {
  width: 100%;
  height: 700px;
}

.closeIcon {
  aspect-ratio: 1;
  background: #000;
  border-radius: 12px;
  border: none;
}

.status {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.usdt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.amount_input {
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-size: 16px;
    color: #fff;
    font-weight: 700;
    margin-right: 16px;
  }
}

.amount_input_field {
  height: 40px;
  border: 1px solid #fff;
  border-radius: 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  h4 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }

  button {
    border: none;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    width: 30px;
    height: 30px;

  }
}

.moonpay_img {
  width: 60%;
  height: auto;
  @media(min-width: 1200px) {
    width: 266px;
    height: auto;
  }
}

.table_wrapper {
  width: 100%;
  height: min-content;
  overflow: hidden;
  object-fit: cover;
  border-radius: 12px 12px 0 0;
  margin-top: 20px;
 }
.music_header {
 height: 40px;
 padding-left: 20px;
 padding-right: 20px;
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 position: relative;
 // margin-bottom: 20px;
}

.title {
 font-family: "Montserrat", sans-serif;
 font-weight: 800;
 text-align: center;
 color: #000;
 text-transform: uppercase;
 font-size: 24px;
}

.return {
 display: block;
 position: absolute;
 right: 20px;
 height: 100%;
}

.share {
 display: block;
 position: absolute;
 left: 20px;
 height: 100%;
}

.popover_content {
 display: flex;
 flex-direction: row;
 button {
  margin: 0 10px;
 }
}
@import '../../scss/variables.scss';
.container {
  display: flex;
  gap: 20px;
  max-width: 390px;

  @media screen and (max-width: $size-s) {
    max-width: 414px;
    justify-content: center;
  }


  .btnWrapper {
    cursor: pointer;
    width: 147px;
    height: 114px;
    background-color: white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.196314);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    &:hover {
      .plus * {
        fill: $light-blue;
      }
      border: 2px solid $light-blue;
    }
    .text {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $placeholder;
      opacity: 0.4;
    }
  }
}

.wrapper {
 display: block;
 padding: 0 20px;
}

.buttons {
 display: flex;
 flex-direction: column;
 margin-top: 18px;
}

.genre_button {
 width: 100%;
 height: 40px;
 border: none;
 border-radius: 12px;
 color: #fff;
 font-weight: 700;
 font-size: 20px;

}

.banner_wrapper {
 width: 100%;
 height: 100%;
 object-fit: cover;
 overflow: hidden;
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: 12px;
 margin-top: 20px;
 margin-bottom: 20px;
}

.banner {
 width: 100%;
 height: auto;
}

.login_btn {
 width: 100%;
 background-color: transparent;
 border: 1px solid #fff;
 color: #fff;
 text-align: center;
 height: 40px;
 border-radius: 12px;

 padding-top: 0;
 padding-bottom: 0;
 font-size: 20px;
 font-weight: 600;
}

.statistic {
 color: #fff;
 margin-bottom: 20px;
 margin-top: 10px;
 text-align: center;
 font-weight: 600;
 font-size: 14px;
}
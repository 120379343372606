.table {
 th {
  background: #C983FF !important;
  border: none !important;
  color: #9507FF !important;
  font-size: 14px;

  .ant-table-cell::before {
   display: none;
  }
 }

 thead {
  tr {
    th {
      padding-top: 7px !important;
      padding-bottom: 7px !important;

    }
   th:nth-child(1) {
    padding-left: 10px !important;
    padding-right: 10px !important;
   }
  }

 }

 tbody {
  tr {
   background-color: #000 !important;

   td:nth-child(1) {
    width: 15px !important;

    div {
     margin: 0 auto;
     p {
      // padding-right: 5px;
      // padding-left: 5px;
      text-align: center;
     }
    }
   }

   td:nth-child(2) {
    width: 20% !important;

    div {
     p {
      padding-right: 10px;
      text-align: end;
     }
    }

    @media(min-width: 1200px) {
     width:  10% !important;
     padding: 20px !important;
    }
   }

   td:nth-child(3) {
    // width: 90px;
    width: 50%;

    div {
     // width: 90px;
     padding: 0 10px;

     @media(min-width: 1200px) {
      padding: auto;
     }
    }

    @media(min-width: 1200px) {
     width: 30%;
    }
   }

   td {
    padding: 0 !important;

    div {
     p {
      font-size: 14px;

      @media(min-width: 1200px) {
       font-size: 16px;
      }
     }
    }
   }
  }
 }

}


.swap * {
 fill: #9507FF;
 margin-right: 10px;
}

.image {
 width: 100%;
 border-radius: 12px;

 // height: 100%;
}

.image_wrapp {
 width: 100px;
 height: 100%;
 display: flex;
 flex-direction: column;

 @media(min-width: 1200px) {
  width: 150px
 }

}

.table_wrapper {
 width: 100%;
 height: min-content;
 overflow: hidden;
 object-fit: cover;
 border-radius: 12px 12px 0 0;
}

.result {
  font-size: 14px !important;
}
@import '../../../scss/variables.scss';



.container {
  background: #000;
  width: 100%;
  height: 80px;
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 10001;
  position: relative;

  @media (max-width: 1200px) {
    width: 100%;
    margin-left: 0px;
  }

  @media(min-width: 1200px) {
    padding: 0 100px;
  }
}

.nav_col {
  min-width: 225px;
  max-width: 225px;
  height: 100%;
  display: flex;
  align-items: center;

}

.account_col {
  min-width: 125px !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_col {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 80px;
}

.ava_wrapper {
  width: 100%;
  height: 40px;
  padding: 0 !important;
  background-color: transparent;
  display: flex;
  align-items: center;
  border: none;
}

.avatar_wrapper {
  width: 30px;
  aspect-ratio: 1;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}

.avatar {
  width: 100%;
}

.balance {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
}

.mobileLayout {
  display: flex;
  flex-direction: row;
}

.layout_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mobileHeader {
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 12px;
  background-color: #000;
}

.ava_wrapper_mobile {
  width: 100%;
  height: 40px;
  padding: 0 !important;
  background-color: transparent;
  display: flex;
  align-items: center;
  border: none;
  align-items: center;
  justify-content: center;
}
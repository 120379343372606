.wrapper {
 display: flex;
 flex-direction: column;
 width: 100%;
 margin-bottom: 10px;
}

.wrapper_info {
 display: flex;
 flex-direction: row;
}

.cover {

 width: 150px;
 object-fit: cover;
 display: flex;
 overflow: hidden;
 align-items: center;
 justify-content: center;
}

.cover_image {
  width: 100%;
  border-radius: 12px;
}

.info_block {
 margin-left: 20px;
 max-width: 150px;
@media(min-width: 992px) {
  max-width: max-content;
}
 p {
   font-size: 14px;
   font-weight: 600;
   color: #fff;
   white-space: nowrap; /* Не переносить текст на новую строку */
   overflow: hidden; /* Скрыть переполненный текст */
   text-overflow: ellipsis; /* Показать многоточие (...) для обрезанного текста */
 }
}
.wrapper {
 display: flex;
 flex-direction: row;
 align-items: center;
 margin: 20px 0;
}

.index {
 font-size: 16px;
 font-weight: 600;
 color: #fff;
 margin-right: 20px;
}

.image_wrapper {
 width: 74px;
 height: 74px;
 border-radius: 50%;
 overflow: hidden;
 object-fit: cover;
 display: flex;
 align-items: center;
 justify-content: center;
 margin-right: 20px;
 border: 3px solid transparent !important;
 /* Ширина рамки */
 border-radius: 10px;
 /* Скругление углов */
 background-image: linear-gradient(black, black), linear-gradient(to bottom, #FFD300, #000000);
 /* Градиент сверху вниз */
 background-clip: padding-box, border-box;
 background-origin: border-box;
 border-radius: 50%;
}

.avatar {
 height: 100%;

}

.info_wrapp {
 display: flex;
 flex-direction: column;
}

.name {
 font-size: 20px;
 font-weight: 700;
 color: #fff;
}

.tropeys {
 display: flex;
 flex-direction: row;
 align-items: center;
}

.small_info {
 font-size: 16px;
 color: #fff;
 text-transform: capitalize;
}

.small_info_left {
 margin-right: 10px;
 color: #fff;
 font-size: 16px;
}
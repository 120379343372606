@import '../../../scss/variables.scss';

.container {
  padding: 20px 20px 0 20px;

  @media screen and (max-width: 370px) {
    padding: 10px 10px 100px 10px;
  }

  @media screen and (max-width: $size-m) {
    padding-bottom: 190px;
  }

  .btnsWrapper {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 20px;
    font-weight: 500;

    @media (max-width: 870px) {
      flex-direction: column;
      align-items: center;
    }

    .activeBtn {
      cursor: pointer;
      font-size: 14px;
      // color: $grey-dark;
      background-color: $white;
      // text-decoration: underline;
      text-decoration-color: #C8102E;
      color: #C8102E;
      // border: 1px solid #C8102E;
      text-decoration-thickness: 4px;
      text-underline-offset: 7px;
      font-family: Montserrat, sans-serif;
    }

    .notActiveBtn {
      cursor: pointer;
      color: $grey-dark;
      background: none;
      font-family: Montserrat, sans-serif;
    }
  }

  .activeHeader {
   color: aliceblue;
  }
}
@import "../../scss/variables.scss";

.options {

    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.21;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    color: #fff;

    @media(min-width: 1200px) {
      font-size: 24px;
    }
	//margin-bottom: 20px;

    @media screen and (max-width: $size-l) {
      margin-right: 0;
    }

    .optionsName {
      mix-blend-mode: normal;
      opacity: 0.65;
    }

    .optionsValue {
      padding: 4px;
      margin: 0 auto;
      width: 220px;
      text-align: justify;
      @media(min-width: 1200px) {
        margin: 0 auto;
        width: 250px;
      }
      // width: 200px;
    }
  }

  .optionsValueWrapp {
    display: flex;
    justify-content: start;
    font-size: 14px;
    @media(min-width: 1200px) {
    font-size: 16px;
    }
  }
  .timer {
    margin-bottom: 20px;
  }

  .bolt * {
    fill: #fff;
  }

  .bolt {
    height: 30px;
    width: 30px;
    position: absolute;
    left: 10px;
  }
@import '../../scss/variables.scss';

.container {
  padding: 0;

  @media screen and (max-width: $size-m) {
    padding: 20px;
  }

  @media(min-width: 1200px) {
    margin-top: 18px;
  }
}

.avatar {
  width: 124px;
  height: 124px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  object-fit: cover;

  @media (max-width: 430px) {
    width: 124px;
    height: 124px;

  }

  @media(min-width: 1200px) {
    width: 160px;
    height: 160px;
  }
}


.avatar_mock {
  font-size: 80px;
}

.nickname {
  display: block;
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 17px;
  margin-bottom: 20px;
  color: #fff;
  @media(min-width: 1200px) {
    font-size: 20px;
  }
}

.input_edit {
  background-color: transparent;
  border: 1px solid #fff !important;
  border-radius: 12px;
  height: 40px;

  ::placeholder {
    color: #fff !important;
  }

  :focus {
    background-color: transparent !important;
    border: 1px solid #fff !important;
  }

  margin-bottom: 10px;
}

.children {
  margin-left: 250px;
  margin-top: -210px;

  @media (max-width: 430px) {
    margin: -160px 0 0 -14px;
    padding: 10px;
  }
}

.bonus_btn {
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 100px;
  display: flex;
  padding: 5px 10px;
  flex-direction: row;
  align-items: center;
  background: linear-gradient(90deg, rgba(241, 32, 39, 1) 0%, rgba(136, 6, 233, 1) 100%);
  border: none;
  @media(min-width: 1200px) {
    font-size: 20px;
  }
  span {
    color: #fff;
  }
}

.bonus_btn_active {
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  padding: 5px 10px;
  border: none;
  @media(min-width: 1200px) {
    font-size: 20px;
  }
  span {
    color: #9507FF;
  }
}

.bonus_icon {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.bonus_icon_active {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  fill: #9507FF !important;
}

.help_btn {
  height: 40px;
  font-size: 16px;
  width: 100%;
  font-weight: 700;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  align-items: center;
}

.help_btn_active {
  height: 40px;
  font-size: 16px;
  font-weight: 700;
  background-color: #fff;
  border: 1px solid #fff;
  color: #9507FF;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  align-items: center;
}

.help_btn_active span{
  color: #9507FF;
}


.profile_btns {
  margin-top: 10px;
  display: grid;
  column-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;

  @media(min-width: 1200px) {
    grid-template-columns: 1fr;
  }
}

.edit_btn {
  height: 40px;
  border: none;
  border-radius: 12px;
  background: #9507FF;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  padding: 5px 10px;
}

.edit_btn_active {
  height: 40px;
  border: none;
  border-radius: 12px;
  background: #fff;
  font-size: 16px;
  font-weight: 700;
  color: #9507FF;
  padding: 5px 10px;

  :hover {
    color: #9507FF !important;
  }
}

.edit_desktop {
  @media(max-width: 1199px) {
    display: none;
  }
}



//   .title {
//     font-weight: 600;
//     font-size: 18px;
//     line-height: 28px;
//     padding-left: 15px;
//     padding-top: 6px;
//     padding-bottom: 4px;
//     /* identical to box height, or 156% */
//      @media (min-width: 431px) {
//         display: none;
//       }

//     color: #2B3134;
//   }
// .container {
//   padding: 20px 20px 168px 20px;
//   width: 100%;
//   padding-bottom: 168px;
//   @media (max-width: 430px) {
//     // width: 390px;
//     margin: 0;
//     padding: 0 0 268px 0;
//     // border-radius: 20px;
//   }
//   .bgHeader {
//     position: relative;
//     height: 218px;
//     border-radius: 20px;
//     background: transparent;
//     z-index: 2;
//     background-repeat: no-repeat;



//   .menuWrapper {
//     margin-left: 36px;
//     margin-top: 30px;

//     @media (max-width: 430px) {
//       display: none;
//     }
//   }
//   .infoWrapper {
//     display: flex;
//     flex-direction: column;
//     color: #000;
//     .name {
//       margin-left: 250px;
//       margin-top: -200px;
//       text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
//       font-weight: 600;
//       font-size: 42px;
//       line-height: 51px;
//       letter-spacing: -0.13125px;
//       @media (max-width: 430px) {
//         margin: -110px 0 10px 120px;
//         max-width: 200px;
//         overflow: hidden;
//         font-size: 21px;
//         white-space: nowrap;
//       }
//     }
//     .userInfo {
//       margin-left: 250px;
//       font-weight: 400;
//       font-size: 16px;
//       line-height: 24px;
//       @media (max-width: 430px) {
//         margin: 0 0 0 120px;
//         max-width: 200px;
//         overflow: hidden;
//         font-size: 16px;
//         height: 45px;
//       }
//     }
//   }
//   .rightHeaderInfoWrapper {
//     z-index: 3;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     margin-top: -110px;
//     margin-left: auto;
//     gap: 35px;
//     padding-right: 20px;
//     @media (max-width: 430px) {
//       margin-left: 0;
//       margin-top: 40px;
//       padding-right: 0px;
//     }

//     .tknInfoWrapper {
//       display: flex;
//       gap: 39px;
//     }
//   }
//   .logo {
//     height: 58px;
//     margin-left: 130px;
//     @media (max-width: 430px) {
//       display: none;
//     }
//   }
//   .tknWrapper {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }
//   .iconWrapper {
//     display: flex;
//     img {
//       margin-right: 8px;
//     }
//     p {
//       font-size: 16px;
//       line-height: 20px;
//       color: #000;
//     }
//   }
//   .tknTitle {
//     color: $light-blue;
//     font-size: 12px;
//     line-height: 15px;
//     margin-bottom: 11px;
//   }
//   .income {
//     color: $lightGreen;
//     font-size: 12px;
//     line-height: 15px;
//     margin-bottom: 11px;
//   }
//   .accountWrapper {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     width: 113px;
//     height: 60px;
//     border: 1px solid #000;
//   }
//   .account {
//     margin-bottom: 11px;

//   }
//   .children {
//     margin-left: 250px;
//     margin-top: -210px;
//     @media (max-width: 430px) {
//       margin: -160px 0 0 -14px;
//       padding: 10px;
//     }
//   }
// }

.plus {
  position: absolute;
  z-index: 10;
  bottom: 0;
  scale: 2;
  right: 0;
  bottom: 5px;
  fill: white;
}

.avatar_wrapp {
  display: block;
  position: relative;
  aspect-ratio: 1 !important;
}

.delete_btn {
  position: absolute;
  left: 0;
  right: 0;
  height: fit-content;
  width: fit-content;
  padding: 0;
}

.child {
  @media(min-width: 1200px) {
    margin-top: 5px;
  }
}

.old_pass {
  margin-bottom: 10px;
}

.new_confirm {
  margin-bottom: 10px;
}

.pass_block {
  padding: 10px;
}
@import '../../../scss/variables.scss';

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 283px;
  height: 62px;
  border: double 3px transparent;
  border-radius: 13px;
  background-origin: border-box;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.253844);
  background-color: $red;
  @media (max-width: $size-s) {
    width: auto;
    height: auto;
  }
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.05px;
    color: $white;
    margin-left: 20px;
    @media (max-width: $size-s) {
      margin-right: 13px;
    }
  }

  .animatedArrow {
    top: 18px;
    left: 30px;
    display: block;
    width: 17px;
    height: 19px;
    margin-right: 15px;
    &* {
      display: block;
      width: 17px;
      height: 19px;
    }

    @media (max-width: $size-s) {
      top: 11px;
      left: 3px;
    }

    @keyframes arrow-left {
      0% {
        transform: translate(0px, 0px);
        opacity: 0;
      }

      100% {
        transform: translate(10px, 0px);
        opacity: 1;
      }
    }
    animation: arrow-left 1s ease-out infinite;
  }
}

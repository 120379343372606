@import '../../../../scss/variables.scss';

.container {
  position: absolute;
  top: 50px;
  left: 90px;
  background-color: $white;
  font-size: 13px;
  line-height: 16px;
  color: $grey-dark;
  z-index: 1;
  background-color: transparent;

  .topContent {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.20334);
    border-radius: 10px 10px 0px 0px;

    > article {
      width: 150px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border-bottom: 1px solid #dfe0e0;
      background-color: $white;

      &:hover {
        background-color: rgb(217, 250, 238);
      }

      > img {
        margin-right: 10px;
      }
      > span {
        margin-left: 10px;
      }
      .icon * {
        fill: #aaadae;
      }
    }
  }
}

.icon {
  margin-right: 10px;
}
.more {
  cursor: pointer;
  .open {
    rotate: 180deg;
  }
  .close {
  }
}

.wrapper {
  background-color: $white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.20334);
  border-radius: 0px 0px 10px 10px;

  .transparent {
    margin-top: 10px;
    background-color: transparent;
  }

  > article {
    width: 150px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: $white;
    border-bottom: 1px solid #dfe0e0;
    color: $text-blue;
    > span {
      margin-left: 10px;
    }

    &:hover {
      background-color: rgb(217, 250, 238);
    }
  }
}

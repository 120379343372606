.auction_header {
 width: 100%;
 height: 40px;
 background-color: #C983FF;
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: 12px 12px 0 0;
}

.auction_header__text {
 font-weight: 700;
 text-transform: uppercase;
 padding: 0;
 margin: 0;
 color: #9507FF;
 font-size: 20px;

}

.action_buttons {
 display: flex;
 flex-direction: row;
 justify-content: space-evenly;
 align-items: center;
 margin-top: 20px;
 margin-bottom: 20px;

 @media(max-width: 990px) {
  flex-direction: column;
  width: 100%;
 }
}


.active_button {
 background: #fff;
 border-radius: 12px;
 height: 40px;
 color: #9507FF !important;
 font-size: 16px;
 border: 1px solid #fff;
 font-weight: 600;

 @media(max-width: 990px) {
  width: 100%;
 }

}

.default_button {
 background: none;
 border-radius: 12px;
 border: 1px solid #fff;
 color: #fff;
 height: 40px;
 font-size: 16px;
 font-weight: 600;

 @media(max-width: 990px) {
  width: 100%;
 }
}

.badge {
 width: min-content ;
 @media(max-width: 990px) {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
 }
}

.create_button {
 background-color: #9507FF;
 color: #fff;
 height: 40px;
 font-weight: 600;
 border: none;
 border-radius: 12px;
 font-size: 16px;

 @media(max-width: 990px) {
  width: 100%;
 }
}

.modal_header {
 display: flex;
 flex-direction: row;
 justify-content: space-between;

 h2 {
  color: #fff;
 }
}

.modal {
 padding: 10px;
}

.body {
 display: flex;
 flex-direction: column;
 margin-top: 20px;
}

.select_body {
 width: 100px;
 height: auto;
}

.label {
 display: flex;
 flex-direction: row;
 align-items: center;
}

.label_cover {
 width: 100px;
 height: auto;
 margin-right: 10px;
}

.inner_image {
 width: 100%;
 margin-left: auto;
 margin-right: auto;
 display: block;
 margin-top: 20px;
 border-radius: 12px;
}

.additional_info {
 display: flex;
 align-items: flex-start;
 flex-direction: column;
}

.text_info {
 color: #fff;
 margin-top: 10px;
 font-size: 16px;
}

.price_input {
 margin-top: 10px;
}

.start_button {
 margin-top: 10px;
 width: 100%;
 height: 40px;
 background-color: #9507FF;
 color: #fff;
 font-weight: 600;
 font-weight: 20px;
 border-radius: 12px;
 border: none;
 display: flex;
 align-items: center;
 justify-content: center;
}

.auction_icon {
 color: #fff;
 fill: #fff;
 margin-right: 10px;
 width: 20px;
 height: 20px;
}

.select_items {
 color: #fff;
}
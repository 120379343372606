.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 17px;
  padding: 30px 0;
  @media(min-width: 1200px) {
    grid-template-columns: 1fr;
    padding: 17px 0;
  }
}

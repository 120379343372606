.wrapper {
 display: flex;
 flex-direction: column;
 margin-top: 20px;
}

.info_wrapp {
 display: flex;
 flex-direction: row;
}

.info {
 display: flex;
 flex-direction: row;
 align-items: center;
}

.image_wrapper {
 // border-radius: 12px;
 // overflow: hidden;
 // object-fit: cover;
 display: flex;
 align-items: center;
 justify-content: center;
 height: 100%;
 border-radius: 12px;
 width: 185px;
}

.image {
 width: 100%;
}

.info_content {
 display: flex;
 flex-direction: column;
 position: relative;
 margin-left: 20px;

 p {
  font-size: 14px;
  color: #fff;
 }
}

.additional {
 height: 40px;
 width: 100%;
 margin-top: 10px;
 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: flex-start;
 border-radius: 12px;
 background: linear-gradient(90deg, #9408FB 0%, #000 100%);
 justify-content: flex-start;

}

.additional_p {
 margin-left: 20px;
 color: #fff;
 font-weight: 600;
}

.trophy {
 display: flex;
 flex-direction: row;
 margin-left: 20px;

 img {
  margin-right: 10px;
 }

 p {
  color: #fff;
  font-weight: 600;
 }
}

.trohpeys {
 display: flex;
 flex-direction: row;
 margin-left: 20px;

 @media(min-width: 1200px) {
  margin-left: 35px;
 }
}

.gift {
 border: 1px solid #fff;
 background-color: transparent;
 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: space-evenly;
 color: #fff;
 fill: #fff;
 width: 120px;
 margin-top: 10px;
 @media(min-width: 1200px) {
  margin-right: 10px;
 }
}

.code_modal {
 padding: 20px 10px
}

.header_modal {
 color: #fff;
 margin-bottom: 10px;
}

.input_field {
 display: flex;
 flex-direction: row;
 align-items: center;
}

.button_copy {
 height: 40px;
 border-radius: 12px;
 border: 1px solid #fff;
 background-color: transparent;
 color: #fff;
}

.popover {
 color: #fff;
 max-width: 300px;
 text-align: justify;
}

.buyback_text {
 color: #fff;
 font-weight: 600;
}

.buyback_amount {
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
}

.buttons_wrapper {
 display: grid;
 grid-template-columns: 1fr;
 @media (min-width: 1200px) {
 grid-template-columns: 1fr 1fr;

 }

}
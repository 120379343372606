@import '../../scss/variables.scss';

.styled.accountName {
  font-size: 32px;
}

.text {
  font-size: 18px;

  &_bold {
    font-size: 18px;
    font-weight: bold;
  }
}

.buy_tokens_header {
  width: 100%;
  height: 40px;
  background-color: #C983FF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px 12px 0 0;
}

.buy_tokens_header__text {
  font-weight: 800;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  color: #9507FF;
  font-size: 20px;
}

.marketplace_btn {
  width: 100%;
  margin-top: 20px;
  border-radius: 12px;
  height: 40px;
  font-size: 16px;
  font-weight: 700;
  color: #9507FF;
  background-color: #C983FF;
  border: none;
  :hover {
    color: inherit;
  }
}

.code {
  display: flex;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  button {
    height: 40px;
    color: #fff;
    background-color: #9507FF;
    border-radius: 12px;
    border: none;
    margin-left: 5px;
  }
}
@import '../../../scss/variables.scss';

.text {
  font-size: 16px;
  color: #fff;
  font-weight: 600;

  //font-weight: 600;
  @media(min-width: 1200px) {
    font-size: 20px;
  }
}

.activeIcon * {
  fill: #9507FF;
  display: block;
}

.icon * {
  display: block;
  fill: #fff;
}

.activeText {
  color: #9507FF !important;
  font-size: 16px;
  font-weight: 600;
  @media(min-width: 1200px) {
    font-size: 20px;
  }
}

.button {
  height: 40px;
  background: transparent;
  padding: 0px 5px;
  border-radius: 12px;
}

.button_active {
  height: 40px;
  background: #fff;
  padding: 0px 5px;
  border-radius: 12px;
}

.button_new {
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin-left: 5px;
  }
}
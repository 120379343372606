@import "../../scss/variables.scss";

.container {
  background-color: #000;
  // width: 225px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 18px;

  top: 0;
  left: 0;

  .menuWrapper {
    margin-bottom: 30px;
  }

  .logo {
    width: 195px;
    margin-top: 10px;
    margin-bottom: 40px;
    // margin: 33px 0 71px 37px;
  }

  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    margin-left: 37px;
  }
}

.elementMenu {
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.rightsWrapper {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .iconsShape {
    display: flex;
    gap: 13px;
  }

  .textWrapper {
    display: flex;
    gap: 8px;
  }

  .text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: $white;
    //opacity: 0.35;
  }
}

.activeIcon * {
  display: block;
  fill: #9507FF;
  margin-right: 10px;
  width: 30px;
  height: 30px;
}

.icon * {
  display: block;
  fill: #fff;
  margin-right: 10px;
  //opacity: 0.6;
  width: 30px;
  height: 30px;
}

.ActiveElementMenu {
  position: relative;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  color: #9507FF;
  font-size: 20px;
  font-weight: 600;
}

.linkElement {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 12px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  padding: 0 10px;
}

.legalLinkElement {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  background-color: transparent;
  border: none;
  border-radius: 12px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  padding: 0 10px;
}


.activeLinkElement {
  display: flex;
  align-items: center;
  border-radius: 12px;
  height: 40px;
  width: 100%;
  color: #9507FF;
  background-color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  padding: 0 10px;
}

.blockLink {
  margin-right: 37px;
}

.webLink {
  cursor: pointer;
}

.social_media {
  display: flex;
  flex-direction: row;
  bottom: -78px;
  position: relative;
  align-items: center;
  margin-left: 14px;
}

.social_media_icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.social_btn {
  width: min-content;
  padding: 0;
  margin: 0;

}

.social_btn_icon {
  width: 30px;
  height: 30px;
}

.logout_btn {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 12px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 20px;
}

.logout_wrapper {
  display: flex;
  align-items: center;
}

.iconLogout {
  display: block;
  fill: #fff;
  margin-right: 10px;
  width: 30px;
  height: 30px;
}

.copy {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-top: 40px;
}
.get_tokens {
 display: flex;
 flex-direction: row;
 justify-content: space-evenly;
 align-items: center;
 margin-top: 10px;
 @media(min-width: 1200px) {
  justify-content: center;
 }
 p {
  span {
   font-size: 16px;
   font-weight: 600;
   font-style: italic;
   color: #fff;
  }
 }
 button {
  display: flex;
  align-items: center;
  border-radius: 100px;
  height: 38px;
  border: none;
  background: linear-gradient(to right, #f12027, #fa0051, #f20081, #d100b6, #8806e9);;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 5px 5px !important;
 @media(min-width: 1200px) {
  margin-left: 10px;
 }
 }
}

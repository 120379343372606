@import "../../scss/variables.scss";

.mobileMenu {
  display: none;

  @media (max-width: 1200px) {
    display: block;
    overflow: hidden;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    background-color: $bg-main !important;
  }
}

.button {
  width: 100%;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  text-align: start;
  height: 40px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 20px;
  font-weight: 600;
}

.button_social {
  width: 100%;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  height: 40px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
}

.social_media_block {
  // margin-top: 20px;
  width: 100%;
  height: 35px;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  a {
    width: min-content;
    height: min-content;
    aspect-ratio: 1;
    padding: 0;
  }
}


.logo {
  width: 150px;
  height: auto;
}

.cancel {
  width: 30px;
  height: auto;
}

.cancel_btn {
  height: fit-content;
  padding: 0;
}

.mobileNavigationWrapper {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  background-color: $bg-main;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 1px solid gray;
  z-index: 101;

  .iconsWrapper {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 80px;
    margin-bottom: 30px;
    background-color: $bg-main;
    align-items: center;


    .userInfoIconsWrapper {
      display: flex;
      gap: 15px;
    }
  }

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    span {
      text-decoration: none;
      color: #000;
      font-weight: 600;
      font-size: 18px;
      line-height: 29px;
    }
  }

  .mainNavigation {
    padding-top: 20px;
    height: 100%;
    display: flex;
    overflow-y: scroll;
    align-items: center;
    flex-direction: column;
    gap: 21px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 15px;
    background-color: $bg-main
  }

  .icon {
    display: block;
  }

  .searchWrapper {
    margin-top: 72px;
    display: flex;
    justify-content: center;
  }

  .profileNavigation {
    display: flex;
    flex-direction: column;
    gap: 21px;
    margin-bottom: 50px;
  }

  .navIcons {
    opacity: 0.8;
  }

  .supportWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }

  .linkSupport {
    font-weight: 600;
    font-size: 14px;
    line-height: 29px;
    color: $white;
  }

  .iconsLinkWrapper {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 20px;
  }
}

.social_media {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
  margin-bottom: 20px;
}

.footer_login {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
  margin-top: 30px;
}

.login_drawer {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 20px;
}

.footer_btn {
  height: 40px;
  font-size: 16px;
  padding: 0 10px;
  font-weight: 600;
  background: transparent;
  color: #fff;
  border: none;
}

.copyright {
  height: 100%;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  line-height: 1.2;
}
@import '../../scss/variables.scss';



.container {

  background: $bg-main;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  @media (max-width: 1200px) {}

  .header {
    @media(min-width: 320px) {
      margin-top: 30px;
    }
    @media(min-width: 1200px) {
      margin-top: 0px;
    }
  }

  .loginWrapper {
    border-radius: 20px;
    width: 100%;
    height: fit-content;
    background-color: $bg-main;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;

    @media (max-width: $size-s) {
      border-radius: 0px;
    }
    @media(min-width: 1200px) {
      margin-top: 45px;
    }
  }

  .logo {
    display: none;
    width: 300px;
    // height: 130px;
    //margin-top: 39px;
    margin-top: 50px;
    margin-bottom: 30px;

    @media(min-width: 1200px) {
      display: block;
    }
  }

  .title {
    margin-top: 28px;
    font-weight: 600;
    font-size: 21px;
    line-height: 26px;
    color: #000;
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.5);
  }

  .text {
    width: 235px;
    text-align: center;
    margin-top: 15px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000;
  }

  .inputData {
    margin-top: 22px;
    width: 320px;
    height: 45px;
    background: $white;
    mix-blend-mode: normal;
    border: 1px solid #000 !important;
    border-radius: 10px;
    padding-left: 18px;
    margin-bottom: 32px;
    display: block;
    margin-left: auto;
    margin-right: auto;

    ::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $bg-main;
    }
  }



  // .inputData:focus {
  //   border: 1px solid red !important;
  // }
}

.invisible {
  display: none;
}
.btn1 {
  width: 100%;
  height: 40px;
  border-radius: 12px;
  padding: 0;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;
}

.btn1_forgot {
  width: 100%;
  height: 40px;
  border-radius: 12px;
  padding: 0;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  font-weight: 700;
  font-size: 20px;

}

.btn2 {
  width: 100%;
  height: 40px;
  border-radius: 12px;
  padding: 0;
  background-color: #9507FF;
  border: none;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

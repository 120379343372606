.wrapper {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.cover {
  width: 100%;
  flex-shrink: 0;
  height: auto;
  border-radius: 12px;

}

.image_container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  object-fit: cover;
  // aspect-ratio: 1.77;
  border-radius: 12px;
  z-index: 10;
}

.big_under {
  position: relative;
  height: 40px;
  // background: #FF75E6;
  margin-left: 10px;
  margin-right: 10px;

  mask-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  // border-radius: 0 0 20px 20px;
  // clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
  top: -4px;

  @media(min-width: 1200px) {
    // border-radius: 0 0 25px 25px;
    height: 40px;
    margin: 0 20px;
    top: -16px;
  }

  @media(min-width: 992px) {
    top: -8px;
  }

  h2 {

    text-align: center;
    font-weight: 500;
    color: #fff;
    transform: none;
    line-height: 1;

    @media(min-width: 1200px) {
      font-size: 14px;
      font-weight: 600;

    }
  }
}

.small_under {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin: 0 20%;
  top: -8px;

  @media(min-width: 1200px) {
    height: 40px;
    margin-left: 50px;
    margin-right: 50px;
    top: -10px;
  }

  h2 {
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    color: #fff;

    @media(min-width: 1200px) {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.counter {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 100;
  height: 100%;
}

.counter_btns {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  height: min-content;

}

.counter_amount {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: normal;
  color: #fff;

  @media(min-width: 1200px) {
    font-size: 14px;
  }
}

.popover_amount {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: #fff;
    margin-bottom: 20px;
  }

  button {
    margin-top: 20px;
    background: #FF75E6;
    border: none;
    color: #fff;
    border-radius: 12px;
  }
}

.confirm_bet {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 12px;
  background-color: #FF75E6;
  margin-left: 55px;
  border: none;
  margin-right: 55px;
  justify-content: center;
  position: relative;
  top: -2px;

  p {
    font-size: 20px;
    font-weight: 600;

  }
}

.confirm_block {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.close_btn {
  background-color: #000;
  border: 1px solid #fff;
  border-radius: 12px;
  aspect-ratio: 1;
  padding: 0;
}

.close_icon {
  color: #fff;
}

.track_info {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    color: #fff;
    font-size: 14px;
    font-weight: 600;

    @media(min-width: 1200px) {
      font-size: 16px;
    }
  }
}

.heart {
  width: 50px;
  height: auto;
}

.titleDesktop {
  position: absolute;
  width: 100%;
  height: 100%;
  fill: #FF75E6;
}

.subtitleDesltop {
  position: absolute;
  width: 100%;
  height: 100%;
  fill: #FFC1F4;
}

.tournaments_title {
  font-size: 12px;
  font-weight: 400;
  color: #FFF;
}

.vimeo {
  width: 100% !important;
  height: 100% !important;
  display: flex;

  justify-content: center;
  aspect-ratio: 16/9;
}

.button_confirm {
  background-color: #9507FF !important;
  color: #FFF !important;

  span {
    color: #fff !important;
  }
}

.button_confirm:hover {
  background-color: #9507FF;
  color: #FFF;

  span {
    color: #9507FF;
  }
}

.white_place {
  background: linear-gradient(to bottom, #9507ff, #ed00bf, #ff007e, #ff0044, #ff4b00);;
  width: 100%;
  display: flex;
  align-items: center;
  border: 2px solid #fff;
  padding: 0 10px;
  height: 65%;
  border-radius: 12px;
}

.winner_info {
	position: absolute;
	display: block;
	z-index: 30;
	color: #fff;
	font-weight: 600;
	font-size: 14px;
  font-family: 'Montserrat';
  top: 50%;
  width: max-content;
  text-shadow: 2px 2px 6px #505050;
  left: 50%;
  transform: translate(-50%, -50%);
  @media(max-width: 1199px) {
		font-size: 12px;
    width: auto;
    text-align: center;
	}
}

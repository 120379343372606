.secondary {
    position: relative;
    padding: 32px 0;

    @media (max-width: 1366px) {
        padding: 25px 0;
    }

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 50%;
        left: 0;
        border: 2px solid rgb(200 16 46 / 100%);
        border-top: 0;
        bottom: 50%;
        z-index: 1;
    }

    &::after {
        content: "";
        position: absolute;
        width: 2px;
        height: 50%;
        top: 50%;
        background-color: rgb(200 16 46 / 100%);
        z-index: 1;
        left: 0;
        right: 0;
        margin: auto;
        @media (min-width: 1366px) {
            // height: calc(50% + 25px);
        }
    }

    &_is-bottom {

        &::before {
            top: initial;
            top: 50%;
            border: 2px solid #1d428a;
            border-bottom: 0;
        }

        &::after {
            top: initial;
            height: 50%;
            bottom: 50%;
            background-color: #1d428a;
        }
    }
}

.thirty {
    position: relative;
    
    // &::before {
    //     content: "";
    //     position: absolute;
    //     width: 2px;
    //     height: calc(50% + 34px);
    //     bottom: 100%;
    //     background-color: rgb(200 16 46 / 100%);
    //     z-index: 1;
    //     left: 0;
    //     right: 0;
    //     margin: auto;
    //     @media (min-width: 1366px) {
    //         // height: calc(50% + 25px);
    //     }
    // }

    // &_is-bottom {
    //     &::before {
    //         bottom: initial;
    //         top: 100%;
    //         background-color: #1d428a;
    //     }
    // }
}
@import "../../scss/variables.scss";

.questionsList {
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(559px, max(559px, 50%)), 559px));
  grid-column-gap: 31px;
  grid-row-gap: 10px; */

  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-between;
  align-items: center;
  color: $grey-dark;
  background: #f5f8fa;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 20%);
  border-radius: 15px;
  padding: 14px;
  margin-top: 10px;
  width: 100%;
  @media screen and (min-width: $size-l) {
    display: block;
    column-count: 2;
  }

  .questionItem {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.20077);
    border-radius: 15px;
    font-weight: 400;
    padding: 16px 13px 18px 21px;
    width: 559px;
    margin-bottom: 10px;
    margin-right: 30px;
    @media screen and (min-width: $size-l) {
      overflow: hidden;
      width: 500px;
    }

    @media screen and (max-width: 430px) {
      width: 100%;
      margin-right: 0px;
    }

    & > h4 {
      overflow: hidden;
      width: calc(100% - 30px);
      font-size: 16px;
      line-height: 1.25;
    }

    .openPath {
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      top: 13px;
      right: 13px;
      border: none;
      background-color: #f5f7f9;
      border-radius: 6.85714px;
      & > svg {
        transform: translate(50%, 150%);
        display: block;
        fill: #000035;
      }
      &:hover {
        cursor: pointer;
      }
    }

    .closePath {
      @extend .openPath;
      transform: rotate(180deg);
      & > svg {
        fill: #0074f0;
      }
    }
    .answer {
      font-size: 14px;
      line-height: 1.71;
      color: $grey-dark;
      opacity: 0.5;
      margin-top: 11px;
    }
  }
}

@import "../../scss/variables.scss";

.battle {
  position: relative;

  @media screen and (max-width: $size-m) {
    max-width: 373px;
  }

  @media screen and (max-width: 370px) {
    max-width: 360px;
  }
}

.bidsAmount {
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
  color: #fff;

  @media(min-width: 1200px) {
    font-size: 20px;
    margin-top: 18px;
  }
}

.songBattleListFirst {
  position: relative;
  display: grid;
  grid-row-gap: 20px;
  justify-content: center;
  margin: 0 auto;
  width: 100%;

  @media screen and (max-width: $size-m) {
    max-width: 373px;
  }

  @media screen and (max-width: 370px) {
    max-width: 360px;
    grid-row-gap: 10px !important;
  }
}

.songBattleListSecond {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 20px;
  margin: 0 auto;
  justify-content: center;
  display: flex;

  @media screen and (max-width: $size-m) {
    max-width: 373px;
    grid-column-gap: 0px;
    grid-row-gap: 20px;
    // padding-bottom: 580px;
  }

  @media screen and (max-width: 370px) {
    max-width: 360px;
  }
}

.songBattleListThird {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 20px;
  margin: 0 auto;

  @media screen and (max-width: $size-m) {
    max-width: 373px;
    grid-column-gap: 0px;
    grid-row-gap: 20px;
    // padding-bottom: 580px;
  }

  @media screen and (max-width: 370px) {
    max-width: 360px;
  }
}

.songListFirst {
  display: grid;
  grid-template-columns: repeat(2, 100%);
  grid-column-gap: 40px;

  @media screen and (max-width: 370px) {
    grid-column-gap: 20px !important;
  }

  &>li:nth-child(odd):after {
    position: absolute;
    display: block;
    // content: url("../../assets/svg/zipper.svg");
    top: 40%;
    left: 102%;

    @media screen and (max-width: $size-m) {
      // left: 47%;
    }
  }

  @media screen and (max-width: $size-m) {
    grid-template-columns: repeat(2, 169px);
    grid-column-gap: 29px;
    grid-row-gap: 0px;
  }
}

.songListSecond {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  overflow-x: auto;
  padding-left: 10px;
  gap: 10px;
  // display: grid;
  // grid-template-columns: repeat(4, 238px);
  // grid-column-gap: 26px;
  // grid-row-gap: 20px;

  >li {
    margin-bottom: 20px;
    flex: 44%;
  }

  // margin: 0 auto;
  padding: 10px;
  // width: 830px;
  // overflow-x: scroll;
  direction: ltr;
  scrollbar-gutter: stable;
  scrollbar-color: #d4aa70 #e4e4e4;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  cursor: pointer;

  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(224, 224, 222);
    mix-blend-mode: normal;
    opacity: 0.2;
    border-radius: 2px;
  }

  &>li:after {
    display: none;
  }

  @media (min-width: $size-m) {
    &>li:nth-child(-n + 3):after {
      position: absolute;
      display: block;
      // content: url("../../assets/svg/zipper.svg");
      top: 40%;
      left: 101%;
    }
  }

  @media (max-width: $size-m) {
    width: 100%;
    overflow-x: unset;

    @mixin transition {
      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          width: 172px;
          height: 256px;
          // margin-left: -($i - 1) * 211 + px;
          z-index: (8 - $i - 1);
          opacity: 1;
        }
      }
    }

    li {
      @include transition;
    }
  }
}

.songListThird {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  overflow-x: auto;
  padding-left: 10px;
  gap: 10px;
  // display: grid;
  // grid-template-columns: repeat(4, 238px);
  // grid-column-gap: 26px;
  // grid-row-gap: 20px;
  margin: 0 auto;
  // width: 830px;
  overflow-x: unset;
  scrollbar-gutter: stable;
  scrollbar-color: #d4aa70 #e4e4e4;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  cursor: pointer;

  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(224, 224, 222);
    mix-blend-mode: normal;
    opacity: 0.2;
    border-radius: 2px;
  }

  &>li:after {
    display: none;
  }

  >li {
    margin-bottom: 20px;
  }

  @media (min-width: $size-m) {
    grid-template-columns: repeat(4, 238px);
    width: 1000px;

    &>li:nth-child(-n + 3):after {
      position: absolute;
      display: block;
      // content: url("../../assets/svg/zipper.svg");
      top: 100%;
      right: -11%;
    }
  }

  @media screen and (max-width: $size-m) {
    grid-template-columns: repeat(8, 238px);
    width: 100%;
    overflow-x: unset;

    @mixin transition {
      @for $i from 1 through 8 {
        &:nth-child(#{$i}) {
          width: 165px;
          height: 256px;
          // margin-left: -($i - 1) * 235 + px;
          z-index: (8 - ($i - 1));
          opacity: 1;
        }
      }
    }

    li {
      @include transition;
    }
  }
}

.middle_content {
  position: absolute;

  @media(max-width: 768px) {
    top: 0px;
  }

  &_vs {
    font-size: 100px;

    @media screen and (max-width: $size-m) {
      font-size: 100px;
    }
  }

  p {
    font-weight: bold;
    font-style: italic;
    font-size: 20px;
    color: #fff;

    @media screen and (max-width: $size-m) {
      font-size: 18px;
    }
  }

  button {
    height: 35px;

    @media(min-width: 768px) {
      height: 40px;
    }
  }

  &_id {
    color: #C8102E;
    font-style: italic;
    font-weight: bold;
  }
}


.battleBtn {
  padding: 0 15px;
  height: 30px;
  border: none;
  bottom: -18px;
  background: linear-gradient(180deg, rgba(200, 16, 46, 1) 0%, rgba(29, 66, 138, 1) 100%);
  animation: pulse 2s infinite;
  transform: scale(1);
  position: absolute;

  @media(min-width: 320px) {
    left: 30px;
  }

  @media(min-width: 992px) {
    bottom: -18px;
    left: -3px;
  }

}


@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}


.desktopMiddleContent {

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 10px;
  text-align: center;

  .battleId {
    height: 50px;
    font-size: 28px;
    font-weight: bold;
    font-style: italic;
    color: $red;
  }

  .battleVs {
    font-size: 40px;
    transform: scale(1, 2);
    font-weight: bold;
  }

  .battleBtn {
    border: none;
    margin-top: 10px;

    button {
      height: min-content;
      padding: 0;
    }
  }
}

.animatedText {
  font-weight: 900;
  text-align: center;
  color: red;
  font-size: 20px;
  font-style: italic;
  animation: glowing 2000ms infinite;
}

@keyframes glowing {
  0% {
    transform: scale(1);
    transition: ease;
  }

  50% {
    transform: scale(0.9);
    transition: ease;
  }

  100% {
    transform: scale(1);
    transition: ease;
  }
}

.vs_icon {
  width: 100px;
  height: auto;

  @media (min-width: 992px) {
    width: 140px;
    height: auto;
  }
}

.timer_wrapper {
  height: 40px;

  background: linear-gradient(90deg, #FF4B01 0%, #9507FF 100%);
  border-radius: 100px;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.comments_wrapper {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.table {
  width: 100% !important;
  th {
    background: #C983FF !important;
    border: none !important;
    color: #9507FF !important;
    font-size: 14px;
    padding: 0 !important;
    .ant-table-cell::before {
      display: none;
    }
  }

  thead {
    tr {
      th {
        background-color: #C8102E !important;
        // padding: 10px !important;
        color: #1d1125 !important;
      }
      th:nth-child(1) {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }

  }

  tbody {
    tr {
      background-color: #000 !important;

      td:nth-child(1) {
        width: 15px !important;
        font-weight: 400;
        padding-left: 10px !important;
        padding-right: 10px !important;
        // padding: 10px !important;
        width: 60px !important;
        div {
          margin: 0 auto;

          p {
            // padding-right: 5px;
            // padding-left: 5px;
            text-align: center;
          }
        }
      }

      td:nth-child(2) {
         width: 150px;
        font-weight: 400;

        div {
          p {
            padding-right: 10px;
            text-align: end;
          }
        }

        @media(min-width: 1200px) {
          width: 30%;
          padding: 0px !important;
        }
      }

      td:nth-child(3) {
        width: 90px;
        font-weight: 400  !important;
        div {
          // width: 90px;
          padding: 0 10px;

          @media(min-width: 1200px) {
            padding: auto;
          }
        }

        @media(min-width: 1200px) {
          width: 30%;
        }
      }

      td:nth-child(4) {
        // width: 30%;
        font-weight: 400;

        width: max-content;

        div {
          padding-right: 5px;

          @media(min-width: 1200px) {
            padding: auto;
          }
        }

        @media(min-width: 1200px) {
          width: max-content
        }
      }

      td {
        padding: 0 !important;

        div {
          p {
            font-size: 14px;

            @media(min-width: 1200px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

}

.info {
  font-size: 11px;
  color: #fff;
  font-weight: 500;
  font-style: italic;
  span {
    font-style: normal;
  }
  @media(min-width: 1200px) {
    font-size: 12px;
  }
}

@import '../../scss/variables.scss';

.container {
  display: flex;
  flex-direction: column;
}
.messageWrapper {
  // width: 891px;
  height: 141px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  background: $white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  justify-content: center;
}
.title {
  // width: 705px;
  height: 51px;
  font-weight: 600;
  font-size: 42px;
  line-height: 51px;
  letter-spacing: -0.13125px;
  color: $bg-main;
  margin-left: 27px;
}
.info {
  // width: 705px;
  height: 48px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $bg-main;
  margin-left: 27px;
}
.textAreaMessage {
  width: 707px;
  height: 109px;
  left: 0%;
  background: $white;
  border: 1px solid $white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.20077);
  border-radius: 10px;
  resize: none;
  padding-top: 14px;
  padding-left: 13px;
}
.btn {
  background: $bg-main;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.253844);
  border-radius: 12.1739px;
  margin-top: 10px;
  width: 279px;
  height: 45px;
  cursor: pointer;
  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: $white;
  }
}
.textAreaWrapper {
  width: 707px;
  margin-top: 13px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}


.send_btn {
  width: 100%;
  height: 40px;
  border-radius: 12px;
  background: #9507FF;
  border: none;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  &:disabled {
    width: 100%;
    height: 40px;
    border-radius: 12px;
    background: #9507FF;
    border: none;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
  }
}
@import "../src/scss/variables.scss";

.container {
  display: block;
  background-color: #000;

  @media screen and (min-width: $size-l) {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: space-between;
  }

  @media(min-width: 1200px) {
    padding: 0 100px;
  }
}

.contentWrapper {
  display: block;
  width: 100%;

  @media screen and (min-width: $size-m) {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}

.button_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
}

.button_wallet {
  width: 125px;
  span {
    font-family: "Montserrat";
    font-weight: 700;
    color: #fff;
    font-size: 20px;
  }
}

.cookie {
  height: min-content;
  width: 100%;
  background: #000;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  border-top: 1px solid #fff;
  padding: 16.5px 24px;

  h3 {
    color: #fff;
  }

  p {
    color: #fff;
  }

  @media(min-width: 1200px) {
    height: 120px;

  }
}

.cookie_info {
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin-right: 20px;
  }
}

.accept_btn {
  padding: 0 20px;
  background-color: #9507FF;
  border: 1px solid #fff;
  border-radius: 12px;
  color: #fff;
}

.account_wrapper {
  height: 30px;
  width: 30px;
  object-fit: contain;
  overflow: hidden;
  border-radius: 50%;
}

.account_avatar {
  width: 100%;
}

.header {
  height: 40px;
}
@import '../../scss/variables.scss';

.wrapper {
  @media (max-width: 1200px) {
    background-color: #000;
    width: 100%;
  }
}

.content {
  margin-top: 10px;
  margin: 0 !important;
  // background-color: rgb(233, 233, 233);
  // background-color: white;
}

.avatar {
  @media(max-width: 1200px) {
    display: none;
  }

  border-radius: 50%;
  object-fit: cover;
  display: block;
}

.logo {
  position: relative;
  width: 150px;
  height: auto;
  @media(min-width: 992px) {
    display: none;
  }
}

.logo_col {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  @media(min-width: 992px) {
    justify-content: flex-end;
  }
  @media (min-width: 1200px) {
    justify-content: flex-start;
  }
}

.button_wallet {
  display: flex;
  align-items: center;
  padding: 0 5px;
  margin-left: 15px;
  @media(min-width: 1200px) {
    display: block;
    margin: 0 auto;
  }
}

.button_wrapper {
  display: flex;
  align-items: center;
}

.profilePic {
  width: fit-content;
  height: fit-content;
}

.logo_button {
  width: fit-content;
  height: fit-content;
  padding: 0;
  margin: 0;
}

.button_link {
  height: fit-content;
  width: fit-content;
  padding: 0 !important;
  margin: 0;
}

.logout_button {
  margin-top: 3px;
  margin-left: 45px;
  @media(min-width: 992px) {
    margin-top: 0;
    margin-left: 0;
  }
}


$white: white;
$bg-main: #000;
$bg-second: #f5f8fa;
$placeholder: #2b3134;
$text-blue: #5eabfe;
$bg-card: #f5f8fa;
$light-blue: #00b2fd;
$lightGreen: #00e476;
$blue-dark: #001542;
$blue: #1D428A;
$blue-light: #00b2fd;
$green: #00e476;
$grey-dark: #2b3134;
$light-asphalt: #f5f7f9;
$sea-green: #00d0ae;
$transparentMenu: #101042;
$cream: #ddd;
$playlist: #1a0938;
$red: #9507FF;

$size-s: 430px;
$size-m: 720px;
$size-l: 1200px;
$size-xl: 1700px;
:root {
	--d: 2500ms;
	--angle: 90deg;
	--gradX: 100%;
	--gradY: 50%;
	--c1: rgba(168, 239, 255, 1);
	--c2: rgba(168, 239, 255, 0.1);
}


.wrapper {
	// height: 40px;
	width: 100%;
	border-radius: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
	animation: borderRotate var(--d) linear infinite forwards;
}

.title {
	font-size: 20px;
	color: #fff;
}

.thunder * {
	fill: #fff;
}



.main_wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;
	padding: 10px;
	margin-bottom: 10px;
	@media(min-width: 1200px) {
		height: 360px;
	}
}

.cover {
	position: relative;
	width: 100%;
	height: auto;
	border-radius: 12px;

	@media(min-width: 1200px) {}
}

.infos {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 100;
	align-items: center;
	height: 100%;
}

.final_info {
	color: #fff;
	font-size: 20px;
	font-weight: 600;
	z-index: 100;
	margin-top: 20px;

	@media(min-width: 1200px) {
		margin-top: 50px;
	}
}

.final_date {
	color: #fff;
	font-size: 20px;
	font-weight: 600;
	z-index: 100;
	margin-bottom: 20px;

	@media(min-width: 1200px) {
		margin-bottom: 50px;
	}
}

@keyframes borderRotate {
	100% {
		--angle: 420deg;
	}
}


@keyframes borderRadial {
	20% {
		--gradX: 100%;
		--gradY: 50%;
	}

	40% {
		--gradX: 100%;
		--gradY: 100%;
	}

	60% {
		--gradX: 50%;
		--gradY: 100%;
	}

	80% {
		--gradX: 0%;
		--gradY: 50%;
	}

	100% {
		--gradX: 50%;
		--gradY: 0%;
	}
}

.title {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 60%;
	margin: 0 auto;

	@media(min-width: 1200px) {
		width: 50%;
	}
}

.tournaments_title {
	font-size: 12px;
	z-index: 1000;
	text-transform: uppercase;
}

.winner_info {
	position: absolute;
	display: block;
	z-index: 30;
	color: #fff;
	font-weight: 600;
	font-size: 18px;
	text-shadow: 2px 2px 6px #505050;
	@media(max-width: 1199px) {
		font-size: 14px;
	}
}
.battleItem {
 display: flex;
 flex-direction: row;
 align-items: flex-start;
 padding: 20px;
 justify-content: flex-start;
 background-color: #FFD2BF;
 @media(min-width: 1200px) {
  justify-content: flex-start;
 }
}

.battleItem_even {
 display: flex;
 flex-direction: row;
 align-items: flex-start;
 padding: 20px;
 justify-content: flex-start;
 @media(min-width: 1200px) {
  justify-content: flex-start;
 }
}

.all_artists {
 color: #000;
 font-weight: 600;
 text-align: center;
}

.all_artists_even {
 color: #fff;
 font-weight: 500;
 text-align: center;
}

.description {
 font-size: 14px;
 line-height: 1.1rem;
 color: #000;
}

.description_even {
 font-size: 14px;
 line-height: 1.1rem;
 color: #fff;
}

.cover {
 width: 100%;
 height: auto;
 border-radius: 12px;
 margin-bottom: 10px;
 // height: 100%;
 // width: auto;

}

.cover_wrapp {
 width: 185px;
 height: auto;
 border-radius: 12px;
 margin-right: 20px;
 display: flex;
 flex-direction: column;
}

.text_wrapper {
 width: 150px;
 @media(min-width: 1200px) {
  width: 100%;
 }
}
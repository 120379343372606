.buy_tokens_header {
 width: 100%;
 height: 40px;
 background-color: #C983FF;
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: 12px 12px 0 0;
}

.buy_tokens_header__text {
 font-weight: 700;
 text-transform: uppercase;
 padding: 0;
 margin: 0;
 color: #9507FF;
 font-size: 20px;

}

table {
 th {
  background: #C983FF !important;
  border: none !important;
  color: #9507FF !important;
  font-size: 14px;

  .ant-table-cell::before {
   display: none;
  }
 }

 thead {
  tr {
   th {
    padding-left: 10px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
   }
   th:nth-child(2) {
    padding-left: 20px !important;
   }
   th:nth-child(1) {
    padding-left: 10px !important;
    padding-right: 10px !important;
   }
  }

 }

 tbody {
  tr {
   background-color: #000 !important;
   td:nth-child(1) {
    width: 15px !important;
    font-weight: 400;
    padding-right: 10px;
    padding-left: 10px;

    div {
     margin: 0 auto;

     p {
      // padding-right: 5px;
      // padding-left: 5px;
      text-align: center;
     }
    }
   }

   td:nth-child(2) {

    div {
     p {
      padding-right: 10px;
      text-align: end;
     }
    }

    @media(min-width: 1200px) {
     width: 50% !important;
     padding: 20px !important;
    }
   }

   td {
    div {
     p {
      font-size: 14px;

      @media(min-width: 1200px) {
       font-size: 16px;
      }
     }
    }
   }
  }
 }

}
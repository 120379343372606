@import "../../scss/variables.scss";

.container {
  margin-left: 266px;
  width: 100%;
  z-index: 9;
  display: flex;
  align-content: center;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  position: fixed;
  left: 0;
  bottom: 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.248786);
  padding: 7px 20px;
  font-size: 14px;
  line-height: 1.43;
  color: $grey-dark;
  @media screen and (min-width: $size-l) and (max-width: $size-xl) {
    width: calc(100% - 90px);
    margin-left: 90px;
  }

  .song {
    display: flex;
    align-items: center;
    margin-right: 35px;
    > div {
      max-width: 246px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    > img {
      display: block;
      margin-right: 13px;
    }
    > h3 {
      font-weight: 600;
      font-size: 14.359px;
      line-height: 1.25;
    }
    > p {
      font-size: 12.5641px;
      line-height: 1.44;
    }
  }

  .controllers {
    display: block;
    margin-right: 35px;

    & * {
      display: block;
    }
    :hover {
      cursor: pointer;
    }
  }
  .controllersSound {
    display: block;
    & > svg {
      display: block;
      fill: #2b3134;
    }
    :hover {
      cursor: pointer;
    }
  }
  .time {
    width: 34px;
    margin-right: 19px;
  }
  .timeSummary {
    width: 34px;
    margin-right: 59px;
  }
  .waveform {
    display: block;
    width: 612px;
    height: 40px;
    margin-right: 19px;
  }
  .volume {
    width: 143px;
    height: 6px;
    margin-right: 55px;
    -webkit-appearance: none;
    background-color: $grey-dark;
  }
  .wallet {
    margin-right: 8px;
    & > svg {
      display: block;
    }
  }
  .price {
    width: 75px;
    text-align: center;
  }

  .wallet {
    & > svg {
      display: block;
    }
  }
}

.SongInfoControllers {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 23px;
    @media screen and (max-width: $size-m) {
      margin-top: 16px;
    }

    .songInfoLike {
      text-transform: uppercase;
      opacity: 0.6;
    }

    .songInfoSvg > svg {
      color:white;
      text-shadow:-1px -1px #333;
    }

    .songInfoSvg:after,
    .songInfoSvg:before {
      display: inline-block;
      margin: auto;
      content: "";
      position: absolute;
      width: 40px;
      height: 40px;
      top: 9px;
      left: 17%;
      border-radius: 100px;
      background: linear-gradient(135.17deg, #00E476 0.29%, #00B2FD 47.83%, #0074F0 100%);
      @media screen and (max-width: $size-m) {
        width: 30px;
        height: 30px;
        top: 6px;
        left: 7px;
      }
    }

    .inactiveSongInfo {
      @extend .songInfoSvg;
      animation: none !important;
      -webkit-animation: none !important;
      -moz-animation: none !important;

      &:after {
        z-index: -100;
          animation: none !important;
        -webkit-animation: none !important;
        -moz-animation: none !important;
      }

      &:before {
        z-index: -200;
        animation: none !important;
      -webkit-animation: none !important;
      -moz-animation: none !important;
      }
    }

    .songInfoSvg {
      width: 59px;
      height: 59px;
      margin: 0 auto;
      display: flex;
      position:relative;
      background: linear-gradient(135.17deg, #00E476 0.29%, #00B2FD 47.83%, #0074F0 100%);
      border-radius: 50%;
      animation: pulse 2000ms linear infinite;
      -webkit-animation: pulse 2000ms linear infinite;
      -moz-animation: pulse 2000ms linear infinite;
      @media screen and (max-width: $size-m) {
        width: 42px;
        height: 42px;
      }

      &:after {
        z-index: -100;
        -webkit-animation: outer-ripple 2000ms linear infinite;
        -moz-animation: outer-ripple 2000ms linear infinite;
        animation: outer-ripple 2000ms linear infinite;
      }

      &:before {
        z-index: -200;
        -webkit-animation: inner-ripple 2000ms linear infinite;
        -moz-animation: inner-ripple 2000ms linear infinite;
        animation: inner-ripple 2000ms linear infinite;
      }

      & > svg {
        width: 59px;
        height: 59px;

        @media screen and (max-width: $size-m) {
          width: 42px;
          height: 42px;
        }
      }
      &:hover {
        cursor: pointer;
      }
      & > * {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .songInfoSvgActive {
      bottom: -11px;
      left: 50%;
      width: 81px;
      height: 81px;
      @media screen and (max-width: $size-m) {
        width: 61px;
        height: 61px;
      }
      @extend .songInfoSvg;
    }
    .songInfoWin {
      text-transform: uppercase;
      opacity: 0.6;
    }
  }


.playerFooter {
  width: 100%;
  height: 60px;
  position: absolute;
  bottom: 0;
  color: #ffffff;
  padding: 10px;
  background-color: #00000097;
  z-index: 99999;
}




.headerDesktop {
  display: none;
  @media(min-width: 768px){
    display: block;
  }
}

.bet_please {
  text-align: center;
  text-transform: uppercase;
  color: #ff0000;
  width: 100%;
  margin-top: 10px !important;
  font-size: 16px;
  @media(min-width: 768px) {
      display: none;
  }
}

.description_mobile {
  @media (min-width: 768px) {
    display: none;
  }
}

.text {
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  font-size: 16px;
  // margin-bottom: 5px;
  // margin-top: 10px;
}

.category {
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  font-size: 16px;
  margin: 0;
}

.pideo_player {

}